import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';

import RegistrationForm from '../../Registration/RegistrationForm';
import PersonalInfoFields from '../../Registration/PersonalInfoForm/PersonalInfoFields';
import ContactInfoFields from '../../Registration/ContactInfoForm/ContactInfoFields';
import { isNullValue } from '../../../../utils/functions';
import { updateSubscriber } from '../../../../state/actions/subscriberActions';
import { updateContactInformation } from '../../../../state/actions/contactInfoActions';
import { setAddresses } from '../../../../state/actions/addressActions';

const PersonalInfoContainer = styled.div`
  max-width: 856px;
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: {}
};

function ProfileInformationForm({ data }) {
  const {
    personal_information_title,
    phone_number_title,
    home_address_title,
    additional_address_title
  } = data.primary;
  const dispatch = useDispatch();
  const {
    identity,
    subscriber,
    addresses: storedAddresses,
    contactInformation
  } = useSelector((state) => state.memberInfo);
  const [isSubscriberSubmitting, setIsSubscriberSubmitting] = useState(false);
  const [isContactInfoSubmitting, setIsContactInfoSubmitting] = useState(false);
  const [isAddressSubmitting, setIsAddressSubmitting] = useState(false);

  // For addresses, we use the useFieldArray helper, which handles its own default values. Therefore, no need to add addresses here
  const defaultValues = {
    firstName: get(subscriber, 'first_name', ''),
    lastName: get(subscriber, 'last_name', ''),
    primaryEmail: get(identity, 'email', ''),
    phoneNumber: get(contactInformation, 'phoneNumber.information', '')
  };

  const useFormObject = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  });

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isValid, errors }
  } = useFormObject;

  const isPersonalInfoValid =
    isNullValue(errors.firstName) &&
    isNullValue(errors.lastName) &&
    isNullValue(errors.primaryEmail) &&
    !!getValues('firstName') &&
    !!getValues('lastName') &&
    !!getValues('primaryEmail');

  const saveSubscriber = (data) => {
    setIsSubscriberSubmitting(true);
    dispatch(
      updateSubscriber(
        data.firstName,
        data.lastName,
        () => {},
        () => setIsSubscriberSubmitting(false)
      )
    );
  };

  const savePhoneNumber = (phoneNumber) => {
    setIsContactInfoSubmitting(true);
    dispatch(
      updateContactInformation(
        contactInformation,
        { phoneNumber: phoneNumber },
        () => {},
        () => setIsContactInfoSubmitting(false)
      )
    );
  };

  const createUpdateOrDeleteAddresses = (data) => {
    setIsAddressSubmitting(true);
    const formattedAddresses = data.addresses.map((address) => {
      const newAddress = { ...address };
      newAddress.addressType = newAddress.addressType.value;
      newAddress.country = newAddress.country.value;
      newAddress.state = newAddress.state.value;
      return newAddress;
    });

    if (storedAddresses.length > 0) {
      const existingAddresses = [];
      const newAddresses = [];

      formattedAddresses.forEach((formattedAddress) => {
        const foundIndex = storedAddresses.findIndex(
          (storedAddress) =>
            storedAddress.addressType === formattedAddress.addressType
        );

        if (foundIndex > -1) {
          existingAddresses.push({
            id: storedAddresses[foundIndex].id,
            ...formattedAddress
          });
        } else {
          newAddresses.push(formattedAddress);
        }
      });

      const deletedAddresses = storedAddresses.filter((storedAddress) => {
        const foundIndex = existingAddresses.findIndex(
          (existing) => existing.addressType === storedAddress.addressType
        );
        return foundIndex < 0;
      });

      dispatch(
        setAddresses(
          () => setIsAddressComplete(true),
          () => setIsAddressSubmitting(false),
          newAddresses,
          existingAddresses,
          deletedAddresses
        )
      );
    } else {
      dispatch(
        setAddresses(
          () => setIsAddressComplete(true),
          () => setIsAddressSubmitting(false),
          formattedAddresses
        )
      );
    }
  };

  const onSubmit = (data) => {
    saveSubscriber(data);
    savePhoneNumber(data.phoneNumber);
    createUpdateOrDeleteAddresses(data);
  };

  return (
    <PersonalInfoContainer>
      <RegistrationForm
        handleSubmit={handleSubmit(onSubmit)}
        nextButtonText='Save Changes'
        removePreviousButton
        customRequiredMessage='Required'
        isValid={isValid}
        isSubmitting={
          isSubscriberSubmitting ||
          isContactInfoSubmitting ||
          isAddressSubmitting
        }
      >
        <PersonalInfoFields
          formTitle={personal_information_title}
          register={register}
          errors={errors}
          isValid={isPersonalInfoValid}
          defaultValues={defaultValues}
        />
        <ContactInfoFields
          useFormObject={useFormObject}
          phoneTitle={phone_number_title}
          homeAddressTitle={home_address_title}
          additionalAddressTitle={additional_address_title}
          storedAddresses={storedAddresses}
          contactInformation={contactInformation}
        />
      </RegistrationForm>
    </PersonalInfoContainer>
  );
}

ProfileInformationForm.propTypes = propTypes;
ProfileInformationForm.defaultProps = defaultProps;

export default ProfileInformationForm;
